/* Colors */
@font-face {
  font-family: Campton;
  src: url("/Themes/comeos/fonts/campton-light-webfont.eot");
  src: url("/Themes/comeos/fonts/campton-light-webfont.eot?#iefix") format("embedded-opentype"), url("/Themes/comeos/fonts/campton-light-webfont.woff2") format("woff2"), url("/Themes/comeos/fonts/campton-light-webfont.woff") format("woff");
}
@font-face {
  font-family: Campton;
  font-weight: bold;
  src: url("/Themes/comeos/fonts/campton-bold-webfont.eot");
  src: url("/Themes/comeos/fonts/campton-bold-webfont.eot?#iefix") format("embedded-opentype"), url("/Themes/comeos/fonts/campton-bold-webfont.woff2") format("woff2"), url("/Themes/comeos/fonts/campton-bold-webfont.woff") format("woff");
}
@font-face {
  font-family: Campton;
  font-weight: 600;
  src: url("/Themes/comeos/fonts/campton-semibold-webfont.eot");
  src: url("/Themes/comeos/fonts/campton-semibold-webfont.eot?#iefix") format("embedded-opentype"), url("/Themes/comeos/fonts/campton-semibold-webfont.woff2") format("woff2"), url("/Themes/comeos/fonts/campton-semibold-webfont.woff") format("woff");
}
.altai-theme-content #bodyView .abouttemplate h1 {
  margin-bottom: 2em;
}
.altai-theme-content #bodyView .abouttemplate h2 {
  margin-top: 2em;
}
.altai-theme-content #bodyView .department {
  clear: both;
  padding-top: 40px;
}
.altai-theme-content #bodyView .dmpicture {
  text-align: center !important;
  margin-top: -90px !important;
  padding-bottom: 0;
  height: 150px;
  /*DM Profile picture in about*/
}
.altai-theme-content #bodyView .dmpicture .profile-picture-dm {
  background: url('https://static.comeos.be/Uploads/DMcutoutAbout.jpg');
  background-size: cover;
  width: 150px;
  height: 150px;
  border: 5px solid #000;
  background-position: center;
  margin-left: auto;
  margin-right: auto;
  border-radius: 50%;
}
.altai-theme-content #bodyView blockquote.about {
  font-size: 0.85em;
  line-height: 1em;
  font-style: italic;
  background-color: #f2f2f2;
  padding: 10px 20px 20px 20px;
  margin: 90px 0 60px 0;
  border-top: 5px solid #000;
  border-left: none;
}
